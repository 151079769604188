import React, { useState, useEffect } from "react";
import "../faq/Faq.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import faq_tl from "../../assets/data/faq_tl.json";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { useToast } from "../../context/ToastContext";

const Faq = () => {
  const {  i18n } = useTranslation();
  const {labels, general} = useToast()
  const [search, setSearch] = useState("");
  const [faq, setFaq] = useState([]);
  const [filterFaqs, setFilterFaqs] = useState([]);

  useEffect(() => {
    const defaultLang = i18n.language.split("-")[0];
    if (defaultLang === "en") {
      setFaq(faq_en);
      setFilterFaqs([...faq_en]);
    } else if (defaultLang === "es") {
      setFaq(faq_es);
      setFilterFaqs([...faq_es]);
    } else if (defaultLang === "pt") {
      setFaq(faq_pt);
      setFilterFaqs([...faq_pt]);
    } else if (defaultLang === "tl") {
      setFaq(faq_tl);
      setFilterFaqs([...faq_tl]);
    }
    setSearch("");
  }, [i18n.language]);

  const emptyFaq = [
    {
      heading: labels.LBL0130,
      noRecords : labels.LBL0131
    },
    {
      heading: labels.LBL0132,
      noRecords : labels.LBL0131
    },
    {
      heading: labels.LBL0133,
      noRecords : labels.LBL0131
    },
    {
      heading: labels.LBL0135,
      noRecords : labels.LBL0131
    }
  ]
  const searchHandler = (event) => {
    if (event) {
      const filterData = filterFaqs.map((f) => {
        const fData = { ...f };
        if (fData.QA && fData.QA.length > 0) {
          fData.QA = f.QA.filter((qa) => {
            if (
              qa.question &&
              qa.question.toLowerCase().includes(event.toLowerCase())
            ) {
              return true;
            }
            if (qa.answer && qa.answer.length > 0) {
              const isFilterdQA = qa.answer
                .map((ans) => {
                  if (
                    ans.content &&
                    ans.content.toLowerCase().includes(event.toLowerCase())
                  ) {
                    return true;
                  }
                  if (
                    ans.content_heading &&
                    ans.content_heading
                      .toLowerCase()
                      .includes(event.toLowerCase())
                  ) {
                    return true;
                  }
                  if (ans.olist && ans.olist.length > 0) {
                    return ans.olist
                      .map((ol) => {
                        if (
                          ol &&
                          ol.toLowerCase().includes(event.toLowerCase())
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .includes(true);
                  }
                  if (ans.ulist && ans.ulist.length > 0) {
                    return ans.ulist
                      .map((ol) => {
                        if (
                          ol &&
                          ol.toLowerCase().includes(event.toLowerCase())
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .includes(true);
                  }
                  return false;
                })
                .includes(true);
              return isFilterdQA;
            }
            return false;
          });
        }
        return fData;
      });

      const mainFilter = filterData.filter((data) => {
        if(data?.QA.length === 0) {
          data.noRecords = labels.LBL0131
       } else {
        delete data.noRecords
       }
        return data?.QA.length > 0 ? true : false;
      });
      setFaq(mainFilter.length > 0 ? filterData : emptyFaq);
    } else {
      setFaq(filterFaqs);
    }
  };

  const escapeRegExpCharacters = (text) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
  };

  const highlightedWord = (content, searchTerm, link = '') => {
    const escapedSearchString = escapeRegExpCharacters(searchTerm);
    const highlightRegex = new RegExp(`(${escapedSearchString})`, "gi");
    let highlightedContent = content.replace('[LINK]', `<a href="${link}" target="_blank">here.</a>`)
    if(escapedSearchString){
      highlightedContent = highlightedContent.replace(
        highlightRegex,
        "<mark>$1</mark>"
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: highlightedContent }} />;
  };

  const renderImageWithText = (text, key) => {
    if (text?.startsWith("/image")) {
      let imageSize = ""
      if(text?.includes("-size")) {
        const textSplit = text?.split("-");
        text = textSplit[0];
        imageSize = textSplit[1]; // values are size1, size2...
       }
      const imageUrl = process.env.REACT_APP_BASE_URL + "/faqimages" + text;
      return <img src={imageUrl} alt={imageUrl} key={key} className={`faq-images ${imageSize}`} />
    } else {
      return (
        <li key={key}>
          {highlightedWord(text, search)}
        </li>
      )
    }
  }

  const renderAppLinks = applinks => {
    return <div className="flex justify-content-center align-items-center">
      {
        applinks.map(app => {
          return (
            <a href={app.link} target="_blank" rel="noreferrer">
              <img src={process.env.REACT_APP_BASE_URL + "/faqimages" + app.image} alt={app.image} className="applink"/>
            </a>
          )
        })
      }
    </div>
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${labels.LBL0136}`}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels.LBL0136} subText={labels.LBL0137} />
      <section className="about-blocks-section faq-main">
        <div className="custom-container">
          <div className="faq-section faq-tabs">
            <div className="search-block">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <PR.InputText
                  value={search}
                  type="search"
                  className="p-inputtext-sm"
                  onChange={(e) => {
                    searchHandler(e.target.value);
                    setSearch(e.target.value);
                  }}
                  placeholder={labels.LBL0138}
                />
              </span>
            </div>
            <PR.TabView>
             {faq &&
                faq.length > 0 &&
                 faq.map((item, i) => (
                  <PR.TabPanel header={item.heading} key={i}>
                    {
                      item.QA
                      ? <div className="card">
                      <div className="col-12 md:col-12">
                        { item.noRecords && <div>{item.noRecords}</div> }
                        <PR.Accordion key={i}>
                          {item.QA.map((qa, j) => (
                            <PR.AccordionTab
                              header={highlightedWord(qa.question, search)}
                              key={j}
                            >
                              {qa.answer.map((an, h) => (
                                <div key={h}>
                                  {an.content_heading && (
                                    <p>
                                      <strong>
                                        {highlightedWord(
                                          an.content_heading,
                                          search
                                        )}
                                      </strong>
                                    </p>
                                  )}
                                  {an.content && (
                                    <p>{highlightedWord(an.content, search, an.link)}</p>
                                  )}
                                  {an.image && (
                                    renderImageWithText(an.image)
                                  )}
                                  {
                                    an.applinks && (renderAppLinks(an.applinks))
                                  }
                                  {an.olist?.length > 0 && (
                                    <ol>
                                      {an.olist.map((anl, nh) => (
                                        <li key={nh}>
                                          {highlightedWord(anl, search)}
                                        </li>
                                      ))}
                                    </ol>
                                  )}
                                  {an.ulist?.length > 0 && (
                                    <ul>
                                      {an.ulist.map((anl, nh) => (
                                            renderImageWithText(anl, nh)
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              ))}
                            </PR.AccordionTab>
                          ))}
                        </PR.Accordion>
                      </div>
                    </div>
                    : item.noRecords
                    }
                    
                  </PR.TabPanel>
                  ))
              }
            </PR.TabView>
          </div>
        </div>
      </section>
      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default Faq;