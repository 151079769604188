import React, { useEffect, useCallback } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { useTranslation } from "react-i18next";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import getAssets from '../../assets/data/brandingAssets.json';
import { useToast } from "../../context/ToastContext";


const Home = () => {
  const { t, i18n } = useTranslation();
  const {labels, home, general, isBase64Image} = useToast()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);

  const handleClick = useCallback(() => {
    navigate('/pricing');
  }, [navigate]);



  useEffect(() => {
    if(scrollToBundlesState) {
      handleClick();
      dispatch(scrollToBundlesActions.desleteSrollToBundles());
    }
  }, [t, i18n, scrollToBundlesState, dispatch, handleClick]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{general.GEN0009}</title>
        <meta property="og:description" content={general.GEN0010} />

      </Helmet>
      {/* Header Section */}
      <section className="header-section" style={{ background: `url(${isBase64Image(home.HOME0001) ?`data:image/png;base64,${home.HOME0001}` : getAssets.home.main_bannerimg}) no-repeat` }}>
        <div className={`header lang-${i18n.language}`}>
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid grid-nogutter header-text">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="header-text-box">
              <h1>{home.HOME0002}</h1>
              <p>{home.HOME0003}</p>
              <PR.Button
                label={home.HOME0004}
                className="goto-plans-button p-button-rounded"
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box"> 
              <PR.Image src={isBase64Image(home.HOME0007) ? `data:image/png;base64,${home.HOME0007}` : getAssets.home.instant_activation} alt="Instant Activation" />
              <h2>{home.HOME0005}</h2>
              <p>{home.HOME0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0010) ? `data:image/png;base64,${home.HOME0010}` : getAssets.home.attractive_rates}  alt="Attractive Rates" />
              <h2>{home.HOME0008}</h2>
              <p>{home.HOME0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0013) ? `data:image/png;base64,${home.HOME0013}` : getAssets.home.global_coverage}  alt="Global Coverage" />
              <h2>{home.HOME0011}</h2>
              <p>{home.HOME0012}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Offer Section */}
      <section className="offer-section">
        <div className="grid column-reverse align-items-center">
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="hand-iphone-img"
                src={isBase64Image(home.HOME0014) ? `data:image/png;base64,${home.HOME0014}` : getAssets.home.unlock_worldBg}
                alt="Connectivity"
              />
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
                <h2>{home.HOME0015}</h2>
                <p>{home.HOME0016}</p>
                <PR.Button label={home.HOME0017} className="choose-plans-button p-button-rounded" onClick={handleClick} />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="grid">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <h2>{labels.LBL0139}</h2>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0020) ? `data:image/png;base64,${home.HOME0020}` : getAssets.home.benifit_section.multi_network_connectivity}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0018}</h3>
                  <p>{home.HOME0019}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0023) ? `data:image/png;base64,${home.HOME0023}` : getAssets.home.benifit_section.fast_connections}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0021}</h3>
                  <p>{home.HOME0022}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0026) ? `data:image/png;base64,${home.HOME0026}` : getAssets.home.benifit_section.easy_installation}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0024}</h3>
                  <p>{home.HOME0025}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0029) ? `data:image/png;base64,${home.HOME0029}` : getAssets.home.benifit_section.support_24_7}
                    alt="Support 24x7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home.HOME0027}</h3>
                  <p>{home.HOME0028}</p>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(home.HOME0033) ? `data:image/png;base64,${home.HOME0033}` : getAssets.home.benifit_section.right_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>

    
    </HelmetProvider>
  );
};

export default Home;